import {
  serviceFeesTotal,
  serviceLaborTotal,
  servicePartsTotal,
  serviceSubTotal,
  serviceTaxTotal,
  serviceBodyLaborTotal,
  servicePaintLaborTotal,
  approvedFeesTotal,
  approvedLaborTotal,
  approvedPartsTotal,
  approvedSubTotal,
  approvedTaxTotal,
  approvedTotal,
  bodyLaborTotal,
  mechanicalLaborTotal,
  paintLaborTotal,
  requestTotal,
  subTotal as proposedSubTotalAmount,
  taxTotal as taxTotalAmount,
  getDiscountAmount,
  serviceDiscountTotal,
  approvedDiscountTotal,
  serviceTotal,
  paintMaterialsTotal,
  servicePaintMaterialsPartTotal,
} from "../lib/request_calculations";
import {
  EstimateWithServices,
  Service,
} from "../../src/requests/request.entity";

import {
  calculateRebate,
  checkIfRebateIsAvailable,
  isEligibleForRebate,
} from "./rebate";

export type Estimate = {
  services: Service[];
  request: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    claim?: any;
    id: number;
    taxRate: number | null | undefined;
    taxRateLaborCollisionRepair: number | null | undefined;
    taxRatePaintSupplyMaterial: number | null | undefined;
    fleetId?: number | null;
  };
  discountInfo?: {
    value?: number | null;
    type?: string | null;
  } | null;
  shopInvoice?: {
    laborExpense?: number | null;
    partsExpense?: number | null;
    otherExpense?: number | null;
  };
  requestCreatedDate?: Date | string | null;
  deductible?: number | null;
  propertyDamageLimit?: number | null;
};

type Taxes = {
  taxRate?: number;
  taxRateLaborCollisionRepair?: number;
  taxRatePaintSupplyMaterial?: number;
};

type PromoCode = {
  value: number;
  type: string;
};

// TODO: This should return EstimatePending type but there are problems typing this on the rest of the codebase
// TODO: There is confusion between Estimate and EstimateWithServices types, as they are different sources of meaning
export const getRequestTotalValues = (
  estimate: Estimate,
  NativeEnv?: { ids: string; date: string }
) => {
  const shopServicesSUConnect = estimate.services.find(
    (service) => service.name === "Shop Services Total"
  );

  if (shopServicesSUConnect) {
    return getConnectRequestValues(estimate, NativeEnv);
  }

  return get360RequestValues(estimate, NativeEnv);
};

type RequestTotals = ReturnType<typeof getRequestTotalValues>;

export function getRequestTotalsInUSD(totals: RequestTotals) {
  return {
    laborCosts: toUSD(totals.laborTotal),
    partsCosts: toUSD(totals.partsTotal),
    additionalCosts: toUSD(totals.feesTotal),
    taxCosts: toUSD(totals.taxTotal),
    discountAmount: toUSD(totals.discountTotal) ?? "$0.00",
    deductible: totals.deductible ? toUSD(totals.deductible) : null,
    rebateTotal: totals.rebateTotal ? toUSD(totals.rebateTotal) : "$0.00",
    totalCosts: toUSD(totals.total),
  };
}

export type RequestTotalsInUSD = ReturnType<typeof getRequestTotalsInUSD>;

export const getRequestServiceValues = (
  service: Service,
  {
    taxRate = 0,
    taxRateLaborCollisionRepair = 0,
    taxRatePaintSupplyMaterial = 0,
  }: Taxes = {}
) => {
  const feesTotal = serviceFeesTotal(service);
  const laborTotal = serviceLaborTotal(service);
  const partsTotal = servicePartsTotal(service);
  const paintSupplyTotal = servicePaintMaterialsPartTotal(service);
  const discountTotal = serviceDiscountTotal(service);
  let subTotal = serviceSubTotal(service);
  const taxTotal = serviceTaxTotal(service, {
    taxRate,
    taxRateLaborCollisionRepair,
    taxRatePaintSupplyMaterial,
  });
  let total = serviceTotal(service, {
    taxRate,
    taxRateLaborCollisionRepair,
    taxRatePaintSupplyMaterial,
  });

  if (discountTotal > 0) {
    subTotal = subTotal - discountTotal;
    total = total - discountTotal;
  }

  console.debug(
    "Calculated service values: %s",
    JSON.stringify({
      feesTotal,
      laborTotal,
      partsTotal,
      paintSupplyTotal,
      discountTotal,
      subTotal,
      taxTotal,
      total,
    })
  );

  return {
    feesTotal,
    laborTotal,
    partsTotal,
    paintSupplyTotal,
    discountTotal,
    subTotal,
    taxTotal,
    total,
  };
};

const get360RequestValues = (
  estimate: Estimate,
  NativeEnv?: { ids: string; date: string }
) => {
  const { request, services, discountInfo, shopInvoice, propertyDamageLimit } =
    estimate;

  //this is ugly solution to get env values from react native but a quick one
  const nativeValue = NativeEnv;

  // HACK: this is a hack around the fact that EstimateWithServices is not related to Estimate or its shape :frowning:
  const est = {
    ...request,
    services,
  } as EstimateWithServices;
  const calculatedValues = getCalculatedValues(est);
  const insuranceValues = getInsuranceValues(estimate, calculatedValues);
  const discountAndRebateValues = getDiscountAndRebateValues(
    request.fleetId,
    estimate.requestCreatedDate,
    insuranceValues,
    calculatedValues,
    discountInfo,
    nativeValue
  );
  const financeValues = getFinanceValues(
    calculatedValues,
    discountAndRebateValues,
    shopInvoice
  );

  const total = Math.max(
    insuranceValues.customerTotal -
      discountAndRebateValues.discountTotal -
      discountAndRebateValues.rebateTotal,
    0
  );

  const values = {
    ...calculatedValues,
    ...insuranceValues,
    ...discountAndRebateValues,
    ...financeValues,
    propertyDamageLimit,
    total,
  };

  if (typeof global.window === "undefined") {
    console.debug("Calculated SU 360 request values price: %o", values);
  }

  return values;
};

const getConnectRequestValues = (
  estimate: Estimate,
  NativeEnv?: { ids: string; date: string }
) => {
  const { request, services, discountInfo, shopInvoice, propertyDamageLimit } =
    estimate;

  //this is ugly solution to get env values from react native but a quick one
  const nativeValue = NativeEnv;

  const shopServicesSUConnect = services.find(
    (service) => service.name === "Shop Services Total"
  );

  if (!shopServicesSUConnect) {
    return get360RequestValues(estimate, nativeValue);
  }

  const validRequest = {
    ...request,
    taxRate: 0,
    taxRateLaborCollisionRepair: 0,
    taxRatePaintSupplyMaterial: 0,
  };

  const validServices = {
    services: services.map((service) => {
      const nonTaxedServiceItems = service.serviceItems?.filter(
        (si) => si.name !== "Tax"
      );
      return {
        ...service,
        serviceItems: nonTaxedServiceItems,
      };
    }),
  };

  const est = { ...validRequest, ...validServices } as EstimateWithServices;

  const calculatedValues = getCalculatedValues(est);
  const insuranceValues = getInsuranceValues(estimate, calculatedValues);
  const discountAndRebateValues = getDiscountAndRebateValues(
    request.fleetId,
    estimate.requestCreatedDate,
    insuranceValues,
    calculatedValues,
    discountInfo,
    nativeValue
  );
  const financeValues = getFinanceValues(
    calculatedValues,
    discountAndRebateValues,
    shopInvoice
  );

  const feesTotal =
    shopServicesSUConnect.serviceItems?.find((si) => si.name === "Other")
      ?.cost ?? 0;
  const taxTotal =
    shopServicesSUConnect?.serviceItems?.find((si) => si.name === "Tax")
      ?.cost ?? 0;
  const customerTotal = shopServicesSUConnect.approved
    ? calculatedValues.approvedServicesTotal + taxTotal
    : 0;

  const total = Math.max(
    customerTotal -
      discountAndRebateValues.discountTotal -
      discountAndRebateValues.rebateTotal,
    0
  );

  const values = {
    ...calculatedValues,
    ...insuranceValues,
    ...discountAndRebateValues,
    ...financeValues,
    customerTotal,
    feesTotal,
    taxTotal,
    propertyDamageLimit,
    total,
  };

  if (typeof global.window === "undefined") {
    console.debug("Calculated SU Connect request values price: %o", values);
  }

  return values;
};

const getCalculatedValues = (estimate: EstimateWithServices) => {
  const taxTotal = approvedTaxTotal(estimate);
  const approvedServicesTotal = approvedTotal(estimate);
  const subTotal = approvedSubTotal(estimate);
  const proposedSubTotal = proposedSubTotalAmount(estimate);
  const laborTotal = approvedLaborTotal(estimate);
  const partsTotal = approvedPartsTotal(estimate);
  const feesTotal = approvedFeesTotal(estimate);
  const discountServicesTotal = approvedDiscountTotal(estimate);
  const allServicesTotal = requestTotal(estimate);
  const mechanicalLabor = mechanicalLaborTotal(estimate);
  const paintLabor = paintLaborTotal(estimate);
  const paintSupplyTotal = paintMaterialsTotal(estimate);
  const bodyLabor = bodyLaborTotal(estimate);
  const approvedCollisionTotal =
    bodyLaborTotalApproved(estimate) + paintLaborTotalApproved(estimate);
  const proposedTaxTotal = taxTotalAmount(estimate);

  return {
    feesTotal,
    approvedServicesTotal,
    laborTotal,
    partsTotal,
    discountServicesTotal,
    subTotal,
    taxTotal,
    allServicesTotal,
    mechanicalLabor,
    paintLabor,
    paintSupplyTotal,
    bodyLabor,
    approvedCollisionTotal,
    proposedSubTotal,
    proposedTaxTotal,
  };
};

const getInsuranceValues = (
  estimate: Estimate,
  calculatedValues: ReturnType<typeof getCalculatedValues>
) => {
  const { approvedServicesTotal } = calculatedValues;
  const { request, propertyDamageLimit } = estimate;
  let insuranceTotal = 0;
  let customerTotal = approvedServicesTotal;
  let deductible = estimate.deductible ?? null;

  if (propertyDamageLimit) {
    insuranceTotal =
      propertyDamageLimit < approvedServicesTotal
        ? propertyDamageLimit
        : approvedServicesTotal;
  }

  if (deductible) {
    insuranceTotal =
      deductible > approvedServicesTotal
        ? 0
        : approvedServicesTotal - deductible;
  } else {
    // Are we using this?
    if (["approved", "paid"].includes(request?.claim?.status)) {
      deductible = request?.claim?.deductible;
    }

    if (deductible !== null) {
      insuranceTotal = Math.max(approvedServicesTotal - deductible, 0);
      customerTotal = Math.max(approvedServicesTotal - insuranceTotal, 0);
    }
  }

  return {
    insuranceTotal,
    customerTotal,
    deductible,
  };
};

const getDiscountAndRebateValues = (
  fleetId: number | undefined | null,
  requestCreatedDate: string | Date | null | undefined,
  insuranceValues: ReturnType<typeof getInsuranceValues>,
  calculatedValues: ReturnType<typeof getCalculatedValues>,
  discountInfo?: Estimate["discountInfo"] | null,
  nativeValue?: { ids: string; date: string }
) => {
  const discountBasis = Math.max(
    insuranceValues.deductible !== null || discountInfo?.type === "dollars"
      ? insuranceValues.customerTotal
      : calculatedValues.laborTotal,
    0
  );

  let discountTotal = isPromoCodeType(discountInfo)
    ? getDiscountAmount(discountInfo, discountBasis)
    : 0;

  if (calculatedValues.discountServicesTotal) {
    discountTotal += calculatedValues.discountServicesTotal;
  }

  let rebateTotal = 0;

  if (
    isEligibleForRebate(fleetId, nativeValue) &&
    checkIfRebateIsAvailable(requestCreatedDate, nativeValue)
  ) {
    rebateTotal = calculateRebate(calculatedValues.approvedCollisionTotal);
  }

  return {
    discountTotal,
    discountBasis,
    rebateTotal,
  };
};

const getFinanceValues = (
  calculatedValues: ReturnType<typeof getCalculatedValues>,
  discountAndRebateValues: ReturnType<typeof getDiscountAndRebateValues>,
  shopInvoice?: Estimate["shopInvoice"]
) => {
  const totalExpense =
    (shopInvoice?.laborExpense ?? 0) +
    (shopInvoice?.partsExpense ?? 0) +
    (shopInvoice?.otherExpense ?? 0);

  const netRevenue = Math.max(
    calculatedValues.subTotal -
      discountAndRebateValues.discountTotal -
      discountAndRebateValues.rebateTotal,
    0
  );
  const totalMargin = netRevenue - totalExpense;
  const totalMarginPercent =
    netRevenue > 0 ? ((totalMargin / netRevenue) * 100).toFixed(2) : 0;

  return {
    totalExpense,
    netRevenue,
    totalMargin,
    totalMarginPercent,
  };
};

type ServiceWithRequiredServiceItems = Omit<Service, "serviceItems"> &
  Pick<Required<Service>, "serviceItems">;

export function isServiceType(
  service: unknown
): service is ServiceWithRequiredServiceItems {
  return Boolean(
    service &&
      typeof service === "object" &&
      "id" in service &&
      typeof service.id === "number" &&
      "serviceItems" in service &&
      typeof service.serviceItems === "object" &&
      "approved" in service
  );
}

export const toUSD = (amountInCents: number) =>
  (amountInCents / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

export function bodyLaborTotalApproved(request: EstimateWithServices) {
  return request.services
    .map((service) => (service.approved ? serviceBodyLaborTotal(service) : 0))
    .reduce((acc, cur) => acc + cur, 0);
}

export function paintLaborTotalApproved(request: EstimateWithServices) {
  return request.services
    .map((service) => (service.approved ? servicePaintLaborTotal(service) : 0))
    .reduce((acc, cur) => acc + cur, 0);
}

export function isPromoCodeType(promoCode: unknown): promoCode is PromoCode {
  return Boolean(
    promoCode &&
      typeof promoCode === "object" &&
      "value" in promoCode &&
      typeof promoCode.value === "number" &&
      "type" in promoCode &&
      typeof promoCode.type === "string"
  );
}

export function transformServiceApproval(services: unknown[]) {
  return services.flatMap((service) => {
    if (!isServiceType(service)) return [];

    const validService = {
      ...service,
      approved: service.approved !== null ? service.approved : true,
    };
    return [validService];
  });
}
