export const isEligibleForRebate = (
  fleetId: number | undefined | null,
  nativeValue?: { ids: string; date: string }
) => {
  const fleets =
    nativeValue?.ids ??
    process.env.NEXT_PUBLIC_FLEET_IDS_ELIGIBLE_FOR_REBATE ??
    process.env.FLEET_IDS_ELIGIBLE_FOR_REBATE ??
    "";

  if (!fleets.length || !fleetId) return false;

  if (fleets == "*") return true;

  const fleetIdsEligibleForRebate = fleets?.split(",");

  return fleetIdsEligibleForRebate.includes(fleetId.toString());
};

export const calculateRebate = (collisionTotal: number, rate = 15) => {
  return collisionTotal <= 0 ? 0 : collisionTotal * (rate / 100);
};

export const checkIfRebateIsAvailable = (
  createdAt: string | Date | undefined | null,
  nativeValue?: { ids: string; date: string }
) => {
  const rebateDate =
    nativeValue?.date ??
    process.env.NEXT_PUBLIC_REBATE_INITIAL_DATE ??
    process.env.REBATE_INITIAL_DATE ??
    "";

  if (!rebateDate || !createdAt) return false;

  return new Date(createdAt) >= new Date(rebateDate);
};
